import {
  createContext,
  useContext
} from "react";

import { STORAGE_KEYS } from "~/constants/storageKeys";
import { usePersistReducer } from "~/hooks/usePersistedReducer";

import {
  initialState,
  onboardingReducer
} from "./onboarding-reducer";

import type {
  State,
  TransformActions
} from "./onboarding-reducer";
import type {
  Dispatch,
  PropsWithChildren
} from "react";

// Create contexts for state
const OnboardingContext = createContext<State>(initialState);

// Create contexts for actions
const OnboardingActionsContext = createContext<Dispatch<TransformActions>>(
  {} as Dispatch<TransformActions> // Safe to typecast we'll never access the initial value
);

// Wraps the providers for both state & action contexts, injecting the reducer to each
export function OnboardingProvider({ children }: PropsWithChildren) {
  const [ state, dispatch ] = usePersistReducer(
    STORAGE_KEYS.LOCAL_STORAGE.ONBOARDING,
    onboardingReducer,
    initialState
  );

  return (
    <OnboardingContext.Provider value={state}>
      <OnboardingActionsContext.Provider value={dispatch}>
        {children}
      </OnboardingActionsContext.Provider>
    </OnboardingContext.Provider>
  );
}

// Make the onboarding state a usable hook
export const useOnboardingState = () => useContext(OnboardingContext);

// Make the actions to alter onboarding state a usable hook
export const useOnboardingDispatch = () => useContext(OnboardingActionsContext);