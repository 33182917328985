import { enumUserRole } from "@roda/graphql/genql";
import { UserRole } from "@roda/shared/types";
import {
  createContext,
  useCallback,
  useContext,
  useMemo
} from "react";
import { useLocalStorage } from "react-use";

import { STORAGE_KEYS } from "~/constants/storageKeys";
import { useAuth } from "~/contexts/AuthContext";
import { useCurrentUser } from "~/contexts/UserContext";
import type { ListCompaniesResponse } from "~/hooks/company";
import { useListCompanies } from "~/hooks/company";
import type { GetCompanyResponse } from "~/hooks/company/use-get-company";

import type { PropsWithChildren } from "react";

interface RodaAdminCompaniesContextProps {
  loading: boolean;
  companies: ListCompaniesResponse | null;
  currentCompany?: GetCompanyResponse | null;
  setCurrentCompany: (company: GetCompanyResponse | null) => void;
  refetchCompanies: () => void;
}

const RodaAdminCompaniesContext = createContext<RodaAdminCompaniesContextProps>({
  loading: false,
  companies: null,
  currentCompany: null,
  setCurrentCompany: () => null,
  refetchCompanies: () => null
});

export const useRodaAdminCompaniesContext = () => useContext(RodaAdminCompaniesContext);

export const RodaAdminCompaniesProvider = (props: PropsWithChildren) => {
  const { user } = useCurrentUser();
  const { authenticated } = useAuth();
  const [ currentCompany, setCurrentCompany ] = useLocalStorage<GetCompanyResponse | null>(STORAGE_KEYS.LOCAL_STORAGE.ADMIN_COMPANY, null);

  // Fetch all of the companies - but only for Roda admins
  const [ { data, fetching }, refetch ] = useListCompanies({
    pause: !authenticated || !user || user.role !== enumUserRole.RODA_ADMIN,
    requestPolicy: "cache-and-network"
  });

  const refetchCompanies = useCallback(() => {
    if (user?.role === UserRole.RODA_ADMIN) {
      refetch({ requestPolicy: "cache-and-network" });
    }
  }, [ refetch, user ]);

  const selectCompany = useCallback((company: GetCompanyResponse | null) => {
    setCurrentCompany(company);
  }, [ setCurrentCompany ]);

  const memoedValue = useMemo<RodaAdminCompaniesContextProps>(
    () => ({
      loading: fetching,
      companies: data ? data.listCompanies : null,
      currentCompany,
      setCurrentCompany: selectCompany,
      refetchCompanies
    }),
    [
      currentCompany,
      data,
      fetching,
      selectCompany,
      refetchCompanies
    ]
  );

  return (
    <RodaAdminCompaniesContext.Provider value={memoedValue}>
      {props.children}
    </RodaAdminCompaniesContext.Provider>
  );
};