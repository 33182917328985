import { FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";
import { useMemo } from "react";

import { FlywheelGoalProgressCard } from "~/components/review/FlywheelGoalProgressCard";
import { Card } from "~/components/Timeline";
import { TimelineLayout } from "~/components/Timeline/TimelineLayout";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import { cn } from "~/utils/cn";
import dayjs from "~/utils/dayjs";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getUnitSymbol } from "~/utils/getUnitSymbol";

interface ReviewGoalTimelineProps {
  subgoalValue: number;
  mainGoalValue: number;
}
export const NextQuarterReviewGoalTimeline: React.FC<ReviewGoalTimelineProps> = ({ subgoalValue, mainGoalValue }) => {
  const isMobile = useIsMobile();

  const {
    flywheel, flywheelSubgoals, checkInSubgoal, subgoalInReview
  } = useSelectedFlywheel();

  // Current subgoal idx
  const checkInSubgoalIdx = useMemo(() => flywheelSubgoals?.findIndex(s => s.id === checkInSubgoal?.id) || 0, [ checkInSubgoal, flywheelSubgoals ]);
  // Subgoal to review idx
  const subgoalToReviewIdx = useMemo(() => flywheelSubgoals?.findIndex(s => s.id === subgoalInReview?.id) || 0, [ subgoalInReview, flywheelSubgoals ]);
  // Get the unit icon
  const unitIcon = useMemo(() => getUnitSymbol(flywheel?.latestFlywheelGoal?.unitTypeLabel, flywheel?.currency), [ flywheel?.currency, flywheel?.latestFlywheelGoal?.unitTypeLabel ]);

  return (
    flywheelSubgoals && (
      <TimelineLayout
        orientation={isMobile ? "horizontal" : "vertical"}
        rowGap={isMobile ? "14" : undefined /* defaults to 60, ideal for desktop */}
        items={flywheelSubgoals.map((subgoal, i, { length }) => {
          const isInQuarter = i === checkInSubgoalIdx;
          const isUpcomingQuarter = subgoalInReview ? subgoal.id === subgoalInReview.id : false;
          const isLastSubgoal = i === length - 1;
          const isAfterCheckInSubgoal = i > checkInSubgoalIdx;
          // Check if the quarter in review is the current quarter
          const rolledIntoQuarter = isInQuarter && isUpcomingQuarter;
          const isTargetedReviewSubgoal = i === subgoalToReviewIdx;
          // If subgoal is the targeted subgoal use the watched subgoal value - otherwise use OG goal
          const targetOfSubgoal = isTargetedReviewSubgoal ? subgoalValue : +subgoal.goal;

          return {
            renderLeft: isLastSubgoal || (!isMobile && isInQuarter) ? (
              <div
                className="flex flex-col items-start gap-2 relative"
                aria-hidden='true'
              >
                {isLastSubgoal && flywheel?.latestFlywheelGoal && (
                  <FlywheelGoalProgressCard
                    flywheelGoalTarget={mainGoalValue}
                    flywheelGoalProgress={flywheel?.latestFlywheelGoal.latestTotalValue}
                    unitIcon={unitIcon}
                    isHealthy={flywheel.latestFlywheelGoal.isHealthy ?? true}
                  />
                )}
              </div>
            ) : undefined,
            renderRight: (
              <Card.TimelineItem
                title={`Quarter ${i + 1}`}
                size={isMobile ? "lg" : "md"}
                subheading={`${dayjs(subgoal.startDate).format("MMMM YYYY")} - ${dayjs(subgoal.endDate).format("MMMM YYYY")}`}
                badge={rolledIntoQuarter ? "Review-due" : isInQuarter ? "Current" : isUpcomingQuarter ? "Coming up" : undefined}
                progressBarProps={{
                  // for percentages NEVER get a sum of updates
                  value: flywheel?.latestFlywheelGoal?.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.PERCENTAGE ?
                    subgoal.updates.length !== 0 ?
                      +subgoal.updates[ subgoal.updates.length - 1 ].value : 0 :
                    subgoal.updates?.reduce((prev, curr) => prev + Number(curr.value), 0) ?? 0,
                  max: subgoal.goal ? Number(subgoal.goal) : Infinity,
                  labelLeft: isAfterCheckInSubgoal || rolledIntoQuarter ? "Progress" : formatGoalNumber(subgoal.latestProgress, unitIcon, {
                    shouldCompact: true,
                    stripTrailingZeros: true
                  }),
                  labelRight: (isAfterCheckInSubgoal && !isTargetedReviewSubgoal) ? "Target" : `Target ${formatGoalNumber(targetOfSubgoal, unitIcon, {
                    shouldCompact: true,
                    stripTrailingZeros: true
                  })}`,
                  neutral: isAfterCheckInSubgoal || rolledIntoQuarter,
                  isHealthy: subgoal.isHealthy ?? true
                }}
                className={cn(isMobile && "mb-0.5", !isTargetedReviewSubgoal && "opacity-45")}
              />)
          };
        })}
      />
    )
  );
};