import { type Flywheel } from "@roda/graphql/genql";
import { UserRole } from "@roda/shared/types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from "react";
import toast from "react-hot-toast";
import { useLocalStorage } from "react-use";

import { STORAGE_KEYS } from "~/constants/storageKeys";
import { useAuth } from "~/contexts/AuthContext";
import { useCustomiseFlywheelGoalDispatch } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useOnboardingDispatch } from "~/contexts/OnboardingContext/OnboardingContext";
import { useRodaAdminCompaniesContext } from "~/contexts/RodaAdminCompaniesContext";
import { SelectedFlywheelProvider } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import type { ListFlywheelType } from "~/hooks/flywheel";
import type { FlywheelResponseData } from "~/hooks/flywheel/use-get-flywheel";
import { useGetFlywheel } from "~/hooks/flywheel/use-get-flywheel";
import type { FlywheelReviewData } from "~/hooks/flywheel/use-get-flywheel-review";
import { useGetFlywheelReview } from "~/hooks/flywheel/use-get-flywheel-review";
import type { ReducedCompanyFlywheelType } from "~/hooks/session/use-get-session";
import { useIsMobile } from "~/hooks/useIsMobile";

import type { PropsWithChildren } from "react";

export type LoadedFlywheel = {steps?: NonNullable<FlywheelResponseData>["steps"]} & NonNullable<ReducedCompanyFlywheelType>[ 0 ] | NonNullable<ListFlywheelType>[ 0 ] | NonNullable<FlywheelResponseData>;
export type LoadedFlywheelGoal = Partial<Flywheel["latestFlywheelGoal"]>;
interface FlywheelLoaderContextProps {
  loading: boolean;
  availableFlywheels: LoadedFlywheel[] | null;
  onDeleteFlywheel: (id: number) => void;
  draftFlywheels: LoadedFlywheel[] | null;
  incompleteFlywheel: LoadedFlywheel | null;
  setActiveFlywheelId: React.Dispatch<React.SetStateAction<string | undefined | null>>
}

const FlywheelLoaderContext = createContext<FlywheelLoaderContextProps>({
  loading: false,
  setActiveFlywheelId: () => null,
  onDeleteFlywheel: () => null,
  availableFlywheels: [],
  draftFlywheels: [],
  incompleteFlywheel: null
});

/**
 * A hook to provide currently loaded flywheels, plus setting the active flywheel
 */
export const useFlywheelLoader = () => useContext(FlywheelLoaderContext);

/**
 * This context is responsible for loading the flywheels in batches:
 * 1. An initial fetch based off user.company.flywheels - enough to load nav
 * 2. A partial fetch to list all flywheels for a company - enough to load the main flywheel page
 * 3. A full fetch for a single flywheel by ID - everything we need for the active flywheel
 */
export const FlywheelLoaderProvider = (props: PropsWithChildren) => {
  const { user } = useCurrentUser();
  const { authenticated } = useAuth();
  const { currentCompany } = useRodaAdminCompaniesContext();
  const [ storedFlywheels, setStoredFlywheels ] = useLocalStorage<LoadedFlywheel[]>(STORAGE_KEYS.LOCAL_STORAGE.FLYWHEEL, []);
  const [ storedFlywheelReview, setStoredFlywheelReview ] = useLocalStorage<FlywheelReviewData | null>(STORAGE_KEYS.LOCAL_STORAGE.FLYWHEEL_REVIEW, null);
  const [ activeFlywheelId, setActiveFlywheelId ] = useLocalStorage<string | null>(STORAGE_KEYS.LOCAL_STORAGE.CURRENT_FLYWHEEL_ID, null);
  const isMobile = useIsMobile();
  const onboardingDispatch = useOnboardingDispatch();
  const customiseFlywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();

  // Req/Res for getting a single flywheel by ID
  const [ getFlywheelRes, getFlywheelRefetch ] = useGetFlywheel(+activeFlywheelId!,
    {
      // Pause if not authenticated, no active flywheel, or no company ID
      pause: !authenticated || !activeFlywheelId || (!user?.company?.id && !currentCompany?.id),
      // Initially, only fetch from the cache. We refetch later via useEffect
      requestPolicy: "cache-only"
    });

  // Req/Res for getting flywheel review
  const [ getFlywheelReviewRes, getFlywheelReviewRefetch ] = useGetFlywheelReview(+activeFlywheelId!,
    {
      // Pause if not authenticated, no active flywheel, or no company ID
      pause: !authenticated || !activeFlywheelId || (!user?.company?.id && !currentCompany?.id),
      // Initially, only fetch from the cache. We refetch later via useEffect
      requestPolicy: "cache-only"
    });

  const onDeleteFlywheel = useCallback((flywheelIdToRemove: number) => {
    const newState = storedFlywheels?.filter(fw => +fw.id !== flywheelIdToRemove);

    setStoredFlywheels(newState);
  }, [ storedFlywheels, setStoredFlywheels ]);

  useEffect(() => {
    // If the user hasn't set an activeFlywheelId and we do have storedFlywheels, then set to the first flywheel!
    if (!activeFlywheelId && !!storedFlywheels?.length) {
      setActiveFlywheelId(user?.company?.flywheels?.filter(fl => fl.setupComplete && !fl.deletedAt)?.[ 0 ]?.id || currentCompany?.flywheels?.filter(fl => fl.setupComplete && !fl.deletedAt)?.[ 0 ]?.id);
    }
  }, [
    currentCompany,
    user,
    activeFlywheelId,
    setActiveFlywheelId,
    storedFlywheels
  ]);

  // 1. An initial fetch based off user.company.flywheels - enough to load nav
  useEffect(() => {
    // set initial state for a normal user
    if (user?.role !== UserRole.RODA_ADMIN && user?.company?.flywheels) {
      // Only overwrite this if we don't currently have full info in state
      // Upon first load let's chuck names & ids into state
      setStoredFlywheels(prevState => {
        // Convert arrays to a Map for easier merging
        const prevMap = new Map((prevState || []).map(item => [ item.id, item ]));
        const newMap = new Map((user?.company?.flywheels || []).map(item => [ item.id, item ]));
        // Create a new map for the final merged state
        const mergedMap = new Map();

        // Iterate over the previous state, retaining the elements if they exist in newState
        for (const [ id, item ] of prevMap.entries()) {
          if (newMap.has(id)) {
            mergedMap.set(id, item);
          }
        }

        // Add any new elements from newState that are not in prevState
        for (const [ id, item ] of newMap.entries()) {
          if (!prevMap.has(id)) {
            mergedMap.set(id, item);
          }
        }

        const newArray = Array.from(mergedMap.values());

        // Convert the merged map back to an array and return it as the new state
        return newArray;
      });
    }

    // Set initial state for roda admins
    if (currentCompany?.flywheels) {
      if (!storedFlywheels?.length) {
        setStoredFlywheels(currentCompany.flywheels as Flywheel[]);
      }
    }

    // If a Roda Admin is not viewing a company then discard any stored data
    if (user?.role === UserRole.RODA_ADMIN && !currentCompany) {
      setActiveFlywheelId(null);
      setStoredFlywheelReview(null);
      setStoredFlywheels([]);
    }
    // setStoredFlywheels causes infinite loop in deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.company?.flywheels,
    setStoredFlywheels,
    currentCompany
  ]);

  // 3.a) Trigger the full fetch to get the current active flywheel - everything else we need for active flywheel
  useEffect(() => {
    // Only if authenticated and we have a companyId
    if (activeFlywheelId && authenticated && (user?.companyId || currentCompany?.id)) {
      // Refetch on load (however if there's already a flywheel, it doesn't block interactivity)
      // We will check the cache and return the result there
      // But always perform a network request too, just in case the flywheel has been updated

      getFlywheelRefetch({ requestPolicy: "cache-and-network" });
    }
    // Don't include refetch in deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authenticated,
    activeFlywheelId,
    user?.companyId,
    currentCompany?.id
  ]);

  // 3.b) Merge the full flywheel into state along with the partial data
  useEffect(() => {
    if (getFlywheelRes.data && (currentCompany || user?.company)) {
      // We have some data!
      if (getFlywheelRes.data.getFlywheel) {
        // Merge the full active flywheel data in with the list response
        const newFlywheel = getFlywheelRes.data.getFlywheel;

        setStoredFlywheels([ ...currentCompany?.flywheels as Flywheel[] || [], ...user?.company?.flywheels as Flywheel[] || [] ].map(prevFlywheel =>
          prevFlywheel.id === newFlywheel.id ? {
            ...prevFlywheel,
            ...newFlywheel
          } : prevFlywheel
        ));

        if (!newFlywheel.setupComplete) {
          if (newFlywheel?.steps?.length) {
            customiseFlywheelGoalDispatch({
              type: "SET_SELECTED_FLYWHEEL_TEMPLATE",
              flywheelTemplate: {
                name: newFlywheel?.name,
                id: newFlywheel?.flywheelTemplateId,
                steps: newFlywheel?.steps?.map((step, idx) => ({
                  id: step.id,
                  name: step.name,
                  order: step.order || idx,
                  description: "",
                  metrics: step.metrics
                })) || []
              }
            });
          }

          if ((newFlywheel as Flywheel).latestFlywheelGoal) {
            customiseFlywheelGoalDispatch({
              type: "SET_SELECTED_FLYWHEEL_GOAL",
              flywheelGoal: {
                cap: (newFlywheel as Flywheel).latestFlywheelGoal?.cap,
                unitDescription: (newFlywheel as Flywheel).latestFlywheelGoal?.unitDescription,
                unitDisplay: (newFlywheel as Flywheel).latestFlywheelGoal?.unitDisplay,
                unitName: (newFlywheel as Flywheel).latestFlywheelGoal?.unitName,
                unitTypeLabel: (newFlywheel as Flywheel).latestFlywheelGoal?.unitTypeLabel,
                name: (newFlywheel as Flywheel)?.latestFlywheelGoal?.name
              }
            });
          }

          onboardingDispatch({
            type: "LOAD",
            flywheel: newFlywheel as Flywheel
          });
        }
      }
    }
  // Do not include storedFlywheels in deps array - we only want to evaluate initial state anyway
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCompany,
    user,
    getFlywheelRes.data,
    setStoredFlywheels
  ]);

  // 4.a) Trigger a fetch for flywheel review
  useEffect(() => {
    // Only if authenticated and we have a companyId
    if (activeFlywheelId && authenticated && (user?.companyId || currentCompany?.id)) {
      // Refetch on load (however if there's already a flywheel, it doesn't block interactivity)
      // We will check the cache and return the result there
      // But always perform a network request too, just in case the flywheel has been updated
      setStoredFlywheelReview(null);
      getFlywheelReviewRefetch({ requestPolicy: "cache-and-network" });
    }
    // Don't include refetch in deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activeFlywheelId ]);

  useEffect(() => {
    if (getFlywheelReviewRes.data?.getFlywheelReview) {
      // We have some data! Store it...
      setStoredFlywheelReview(getFlywheelReviewRes.data.getFlywheelReview);
    }
  }, [
    user,
    getFlywheelReviewRes.data,
    setStoredFlywheelReview
  ]);

  // If we're loading a single flywheel...
  useEffect(() => {
    if (getFlywheelRes.fetching) {
      toast.loading("Checking for updates", {
        id: "flywheel_refetch_toast",
        position: isMobile ? "top-center" : "top-right",
        className: "-mt-px [&_[role='status']:empty]:m-0"
      });
    } else {
      toast.dismiss("flywheel_refetch_toast");
    }
  }, [ getFlywheelRes.fetching, isMobile ]);

  const memoedValue = useMemo<FlywheelLoaderContextProps>(
    () => ({
      loading: getFlywheelRes.fetching,
      setActiveFlywheelId,
      onDeleteFlywheel,
      availableFlywheels: storedFlywheels ? storedFlywheels?.filter(fl => fl.setupComplete === 1 && fl.deletedAt === null) : [],
      draftFlywheels: storedFlywheels ? storedFlywheels?.filter(fl => fl.setupComplete === 0 && fl.deletedAt === null) : [],
      incompleteFlywheel: storedFlywheels ? storedFlywheels?.filter(fl => fl.setupComplete === 0 && fl.deletedAt === null)[ 0 ] : null
    }),
    [
      getFlywheelRes.fetching,
      setActiveFlywheelId,
      storedFlywheels,
      onDeleteFlywheel
    ]
  );

  return (
    <FlywheelLoaderContext.Provider value={memoedValue}>
      <SelectedFlywheelProvider
        flywheels={storedFlywheels as Flywheel[]}
        loading={getFlywheelRes.fetching}
        activeFlywheelReview={storedFlywheelReview}
        refetchCurrentFlywheel={getFlywheelRefetch}
        setActiveFlywheelId={setActiveFlywheelId}
        activeFlywheelId={activeFlywheelId}
        setStoredFlywheels={setStoredFlywheels}
      >
        {props.children}
      </SelectedFlywheelProvider>
    </FlywheelLoaderContext.Provider>
  );
};